// import './App.css';
import '../../App.css'
import { useSelector } from 'react-redux';

function News() {
    const userInfos = useSelector((state) => state.news); // Use selector to access state
    const userNews = userInfos?.news.length > 0 ? userInfos?.news : [];

    return (
        <>
            {
                userNews?.map(news => {
                    return (
                        news?.message
                    )
                })
            }
        </>
    );
}


export default News;
