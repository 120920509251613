import '../../App.css'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Appconfig from '../../config/config'
import '../../../src/styles.css';
import CasinoBetPlace from '../../components/casinoBetPlace'
import CasinoVideo from 'components/casinoVideo';
import { useDispatch,useSelector } from 'react-redux';
import { setData } from '../../redux/slice/betting/bettingSlice';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import SkyLoader from 'components/sky-loader';


export default function DRAGONTIGER1DAY() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state?.userInfo?.userdata?.value); // get login user details

    const [loadCasino, setLoadCasino] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    // console.log('casinoData', casinoData);
    const [casinoEventId, setCasinoEventId] = useState('');
    const [casinoRoundId, setCasinoRoundId] = useState('');
    const [casinoCardRoundId, setCasinoCardRoundId] = useState('');


    const [marketState, setMarketState] = useState([]);
    const [cardsState, setCardsState] = useState('');
    const [resultState, setResultState] = useState([]);
    const [casinoStatus, setCasinoStatus] = useState('');

    // console.log('casinoStatus', casinoStatus);

    const [winnerName, setWinnerName] = useState('');

    const [showBetsSlip, setShowBetsSlip] = useState(false);
    const [result, setResult] = useState(false);


    const [chips, setChips] = useState([]);
    const [placing, setPlacing] = useState(false);
    const [type, setType] = useState('back')
    const [selection_id, setSelection_id] = useState('')
    const [market_id, setMarket_id] = useState('')


    const [htmlId, setHtmlId] = useState('')
    const [BetPlaceData, setBetPlaceData] = useState({
        event_id: "",
        round_id: "",
        market_id: "",
        is_back: "",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
        market_name: "",
        PM_FANCY: false
    });
    // console.log('BetPlaceData', BetPlaceData.market_name);
    const [ProfitValue, setProfitValue] = useState(0);
    const [lossValue, setLossValue] = useState(0);
    const [StakeValue, setStakeValue] = useState(0);
    const [size, setSize] = useState('')
    const [is_fancy, setIs_fancy] = useState(false)
    const [defaultStake, setDefaultStake] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [position, setCasinoPosition] = useState([])
    const [cardType, setCardType] = useState('DRAGON CARD')

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        setTimeout(() => {
            setLoadCasino(false);
        }, 1000)
    })
    const [seconds, setSeconds] = useState(45);
    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(45);
        }
    }, [seconds]);

    useEffect(() => {
        if (result) {
            dispatch(setData(new Date().toISOString()));
        }
    }, [result])

    const preDefineData = [
        {
            "marketId": "9212410082127",
            "marketName": "WINNER",
            "min": 100,
            "max": 100000,
            "index": 0,
            "runners": [
                {
                    "selectionId": "38372466",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.98
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 2.02
                            }
                        ]
                    }
                },
                {
                    "selectionId": "3884437",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.98
                            }
                        ],
                        "lay": [
                            {
                                "size": 1000000,
                                "price": 2.02
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "3884437": "TIGER",
                "38372466": "DRAGON"
            }
        },
        {
            "marketId": "9212410082128",
            "marketName": "PAIR",
            "min": 100,
            "max": 100000,
            "index": 1,
            "runners": [
                {
                    "selectionId": "34375487",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 12
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "34375487": "PAIR (TIE)"
            }
        },
        {
            "marketId": "9212410082129",
            "marketName": "DRAGON ODD/EVEN",
            "min": 100,
            "max": 100000,
            "index": 2,
            "runners": [
                {
                    "selectionId": "3724660995566",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.81
                            }
                        ]
                    }
                },
                {
                    "selectionId": "3724660665566",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 2.14
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "3724660995566": "DRAGON ODD",
                "3724660665566": "DRAGON EVEN"
            }
        },
        {
            "marketId": "9212410082130",
            "marketName": "TIGER ODD/EVEN",
            "min": 100,
            "max": 100000,
            "index": 3,
            "runners": [
                {
                    "selectionId": "844370995566",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.81
                            }
                        ]
                    }
                },
                {
                    "selectionId": "844370665566",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 2.14
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "844370995566": "TIGER ODD",
                "844370665566": "TIGER EVEN"
            }
        },
        {
            "marketId": "9212410082131",
            "marketName": "DRAGON CARD COLOR",
            "min": 100,
            "max": 100000,
            "index": 4,
            "runners": [
                {
                    "selectionId": "58259733",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.97
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825925225",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.97
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "58259733": "RED",
                "5825925225": "BLACK"
            }
        },
        {
            "marketId": "9212410082132",
            "marketName": "TIGER CARD COLOR",
            "min": 100,
            "max": 100000,
            "index": 5,
            "runners": [
                {
                    "selectionId": "58259733",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.97
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825925225",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 1000000,
                                "price": 1.97
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "58259733": "RED",
                "5825925225": "BLACK"
            }
        },
        {
            "marketId": "9212410082133",
            "marketName": "DRAGON CARD",
            "min": 100,
            "max": 20000,
            "index": 6,
            "runners": [
                {
                    "selectionId": "5825901",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825902",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825903",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825904",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825905",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825906",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825907",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825908",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825909",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259010",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259011",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259012",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259013",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "5825901": "A",
                "5825902": "2",
                "5825903": "3",
                "5825904": "4",
                "5825905": "5",
                "5825906": "6",
                "5825907": "7",
                "5825908": "8",
                "5825909": "9",
                "58259010": "10",
                "58259011": "J",
                "58259012": "Q",
                "58259013": "K"
            }
        },
        {
            "marketId": "9212410082134",
            "marketName": "TIGER CARD",
            "min": 100,
            "max": 20000,
            "index": 7,
            "runners": [
                {
                    "selectionId": "5825901",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825902",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825903",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825904",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825905",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825906",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825907",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825908",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "5825909",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259010",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259011",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259012",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                },
                {
                    "selectionId": "58259013",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 50000,
                                "price": 12
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "5825901": "A",
                "5825902": "2",
                "5825903": "3",
                "5825904": "4",
                "5825905": "5",
                "5825906": "6",
                "5825907": "7",
                "5825908": "8",
                "5825909": "9",
                "58259010": "10",
                "58259011": "J",
                "58259012": "Q",
                "58259013": "K"
            }
        }
    ];

    useEffect(() => {
        const casinowebsocket = new WebSocket("wss://ws.operator.buzz:9093/");
        if (casinowebsocket) {


            casinowebsocket.onopen = () => {
                console.log('WebSocket connection opened');
                let sendReq = {
                    'event_id': Appconfig.casino_type_id['1daydt'],
                }
                casinowebsocket.send(JSON.stringify({ action: 'JOIN', data: sendReq }));

                // Send a test message to the server (if applicable)
            };




            casinowebsocket.onmessage = function (message) {
                const casinos = JSON.parse(message?.data);

                if (casinos?.action == "MARKET_UPDATE" && casinos?.event_type == Appconfig.casino_type_id['1daydt']) {
                    let casinoss = casinos?.data;

                    if (casinoss) {
                        setResult(false);
                        if (casinoss?.skyPlaceBet.length > 0) {

                            if (casinoss?.skyPlaceBet[0]?.data.status) {
                                setCasinoStatus(casinoss?.skyPlaceBet[0]?.data.status);
                                if (casinoss?.skyPlaceBet[0]?.data?.marketArr && Array.isArray(casinoss?.skyPlaceBet[0]?.data?.marketArr)) {
                                    setMarketState(casinoss?.skyPlaceBet[0]?.data?.marketArr);
                                }
                                setCasinoEventId(casinoss?.skyPlaceBet[0]?.eventId)
                                setCasinoRoundId(casinoss?.skyPlaceBet[0]?.roundId)

                                if (casinoss?.skyPlaceBet[0]?.data.status == "SUSPEND") {
                                    setShowBetsSlip(false);
                                }
                            }
                        }else{
                            setMarketState(preDefineData);
                            setCasinoStatus("SUSPEND");
                        }


                        if (casinoss?.skyCardScan) {
                            if (casinoss?.skyCardScan[0]?.data?.cardsArr) {
                                setCardsState(casinoss?.skyCardScan[0].data?.cardsArr);
                                // setCasinoEventId(casinoss?.skyCardScan[0]?.eventId)
                                setCasinoCardRoundId(casinoss?.skyCardScan[0]?.roundId)

                            }
                        }

                        if (casinoss?.skyResultDeclared) {
                            if (casinoss?.skyPlaceBet[0]?.roundId == casinoss?.skyResultDeclared[0]?.roundId) {
                                setResultState(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                getWinnerName(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                setTimeout(() => {
                                    setResult(true);
                                }, 4000)
                            } else {
                                setWinnerName('');
                            }
                        }

                    }

                }



            }



            casinowebsocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            casinowebsocket.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
            };


        }

    }, []);


    function getWinnerName(resultsArr) {
        // Find the market with the name "WINNER"
        const winnerMarket = resultsArr.find(market => market.marketName === "WINNER");

        if (!winnerMarket) {
            return "No winner market found";
        }

        // Find the runner ID associated with the "WINNER"
        const winnerRunnerId = Object.keys(winnerMarket.runners).find(runnerId => winnerMarket.runners[runnerId] === "WINNER");

        if (!winnerRunnerId) {
            return "No winner found";
        }

        // Get the winner name using the runner ID
        const winnerName = winnerMarket.runnersName[winnerRunnerId];

        setWinnerName(winnerName + ' Win');
    }



    useEffect(() => {
        if (userInfo) {
            getChips();
            getAuraCasinoPosition();
        }
    }, [])

    function getAuraCasinoPosition() {
        var data = JSON.stringify({
            user_id: userInfo?._id,
            event_type: Appconfig.casino_type_id["1daydt"],
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getAuraCasinoPosition`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log('response', response);

                if (response.data.result) {
                    let casinoPos = response.data.resultData;

                    if (casinoPos.length > 0) {
                        casinoPos.forEach((element) => {

                            // if ((element.market_name == "WINNER" && element.event_type == "1004") || (element.market_name == "WINNER" && element.event_type == "1008")) {
                            //     var elements = document.getElementsByClassName(
                            //         `selection_position_${element.selection_id}`
                            //     );
                            //     // console.log('elements', elements);
                            //     for (var el of elements) {
                            //         el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                            //         el.style.color = element.total_pl > 0 ? "green" : "red";
                            //     }
                            // } else {
                            var runners = document.getElementsByClassName("runners_" + element.market_id);

                            for (var item of runners) {
                                var selecid = item.getAttribute("data-id");

                                var elements = document.getElementsByClassName(
                                    `position_${element.market_id}_${selecid}`
                                );
                                // console.log('elements', elements);
                                for (var el of elements) {
                                    if (selecid == element.selection_id) {
                                        el.innerHTML = Math.abs(element.profit.toFixed(2));
                                        el.style.color = "green";
                                    } else {
                                        el.innerHTML = Math.abs(element.loss.toFixed(2));
                                        el.style.color = "red";
                                    }
                                }
                            }
                            // }


                        });
                        setCasinoPosition(response.data.resultData);
                    }

                    // if (casinoPos.length > 0) {
                    //   casinoPos.forEach((element) => {
                    //     var elements = document.getElementsByClassName(
                    //       `selection_position_${element.selection_id}`
                    //     );
                    //     // console.log('elements', elements);
                    //     for (var el of elements) {
                    //       el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                    //       el.style.color = element.total_pl > 0 ? "green" : "red";
                    //     }
                    //   });
                    //   setCasinoPosition(response.data.resultData);
                    // }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function getChips() {
        if (userInfo) {
            var data = JSON.stringify({
                user_id: userInfo._id,
            });
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}chips/getChips`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    try {
                        if (response.status) {
                            setChips(response.data)
                        }
                    } catch (e) {
                        console.log(e)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            navigate('/login')
        }
    }


    function SetPosition(stake, priceVal, market_id, is_back, selection_id, market_name) {
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId;
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        var runners = document.getElementsByClassName("runners_" + MId);
        // console.log('runners', runners);
        // return false;
        var tempRunners = "";
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = 0
            // winloss = parseFloat(item.value);
            var curr = 0;
            // console.log({
            //     'selecid': selecid,
            //     'selectionId': selectionId,
            //     'market_name': market_name,
            //     'priceVal': priceVal,
            //     'stake': stake,
            //     'winloss': winloss,
            //     'isback': isback,
            // });

            if (selectionId == selecid) {
                if (market_name == "WINNER") {
                    if (isback) {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + ((priceVal * stake) / 100);
                        // } else {
                        curr = winloss + ((priceVal * stake) - stake);
                        // }
                    } else {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                        // } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                        // }
                    }
                } else {
                    curr = winloss + ((priceVal * stake) - stake);
                }

            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = curr;

            var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);
            // console.log('elements', elements);
            for (var el of elements) {
                el.innerHTML = Math.abs(currV.toFixed(2));
                el.style.color = currV > 0 ? 'green' : 'red';
            }

        }
    }


    function calc(t_stake, priceVal, selection_id) {
        var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back
        if (!isfancy) {
            var pl = ((priceVal * t_stake) - t_stake);

            pl = parseFloat(pl.toFixed(2));
            if (isback) {
                setProfitValue(pl)
                setLossValue(t_stake)
            } else {
                setLossValue(pl)
                setProfitValue(t_stake)
            }
            // SetPosition(priceVal);
        }
    }

    const placeStakeValue = (stake) => {
        if (String(stake).startsWith("NaN")) {
            stake = String(stake).replace("NaN", "");
        }
        setStakeValue(parseFloat(stake));
        calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);
    };

    const cancelBetSlip = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);

        setShowBetsSlip(false);
        setSelection_id('');
        setHtmlId('');
    }


    const handleOpenBetSlip = (
        event_id,
        round_id,
        market_id,
        is_back,
        size,
        is_fancy,
        selection_id,
        runner_name,
        htmlId,
        PM_FANCY = null,
        market_name
    ) => {
        setShowBetsSlip(true);
        setSelection_id(selection_id)
        setMarket_id(market_id)
        setType(is_back ? 'back' : 'lay')
        setHtmlId(htmlId)
        setSize(size)
        setIs_fancy(is_fancy)
        console.log({
            'event_id': event_id,
            'market_id': market_id,
            'is_back': is_back,
            'size': size,
            'is_fancy': is_fancy,
            'selection_id': selection_id,
            'runner_name': runner_name,
            'new_price': htmlId,
            'PM_FANCY': PM_FANCY,
            'market_name': market_name,
        })

        // console.log('yes ')

        let selectElements = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id);


        setProfitValue(0);
        setStakeValue(0)
        var priceNew = htmlId;

        setBetPlaceData({
            event_id: event_id,
            round_id: round_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
            market_name: market_name,
            PM_FANCY: PM_FANCY
        });
        setTimeout(function () {
            // SetPosition(0, priceNew, market_id, is_back, selection_id, market_name)
            calc(0, priceNew, selection_id)
            placeStakeValue(defaultStake);
        }, 800);
    }




    function betPlace(ishow) {

        if (userInfo) {
            if (userInfo.user_type == "User") {


                if (StakeValue > 99) {

                    setPlacing(true);
                    setIsLoading(true);

                    var data = JSON.stringify({
                        user_id: userInfo._id,
                        match_id: BetPlaceData.event_id,
                        round_id: BetPlaceData.round_id,
                        selection_id: BetPlaceData.selection_id,
                        is_back: BetPlaceData.is_back,
                        stake: StakeValue,
                        price_val: BetPlaceData.price,
                        market_id: BetPlaceData.market_id,
                        is_fancy: "No",
                        market_name: BetPlaceData.market_name,
                        runner_name: BetPlaceData.runner_name,
                        event_name: "1-Day Dragon Tiger",
                        profit: ProfitValue,
                        loss: lossValue,
                        pm_fancy: BetPlaceData.PM_FANCY,
                        event_type: Appconfig.casino_type_id['1daydt'],
                        is_casino: 'Yes'
                    });


                    var config = {
                        method: "post",
                        url: `${Appconfig.apiUrl}betting/auraAddBetting`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };
                    // alert("success");
                    axios(config)
                        .then(function (response) {

                            dispatch(setData(new Date().toISOString()));

                            try {
                                setPlacing(false);
                                setSelection_id('')
                                setHtmlId('')
                                setProfitValue(0);
                                setStakeValue(0);
                                setShowBetsSlip(false)


                                if (response.data.result == 0) {
                                    // console.log('response:', response.data.resultMessage)
                                    NotificationManager.error(response.data.resultMessage, '', 3000);
                                } else {
                                    NotificationManager.success(response.data.resultMessage, '', 3000);

                                }
                                var MId = BetPlaceData.market_id;
                                var selectionId = BetPlaceData.selection_id;
                                var runners = document.getElementsByClassName("runners_" + MId);


                                for (var item of runners) {
                                    var selecid = item.getAttribute('data-id');

                                    var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);

                                    for (var el of elements) {
                                        el.innerHTML = '';
                                    }

                                }

                                setBetPlaceData({
                                    event_id: "",
                                    round_id: "",
                                    market_id: "",
                                    is_back: "",
                                    price: "",
                                    is_fancy: "",
                                    selection_id: "",
                                    runner_name: "",
                                    market_name: "",
                                });
                                getAuraCasinoPosition();
                            } catch (e) {
                                console.log(e.message)
                            }
                            setIsLoading(false);

                        })
                        .catch(function (error) {
                            console.log(error);
                            console.log(error.message)
                            setIsLoading(true);

                        });
                }
                else {
                    NotificationManager.error('Min Stack Value Is 100', '', 3000);
                }

            }
        }
        else {
            navigate('/login')
        }
    }

    const CustomContent = ({ market }) => {
        return (
            <span className='lg:block text-[.75rem] font-bold text-[#ffffff]'>
                Min/Max: {market?.min + ' - ' + market?.max}
            </span>
        );
    };

    return (
        <>
            {
                isLoading && <SkyLoader />
            }
            {
                loadCasino ?
                    <div className='block absolute w-full left-0 top-0 h-screen overflow-hidden'>
                        <div className='fixed top-0 relative w-full h-screen flex justify-center items-center bg-[#000000] z-[99999]'>
                            <img src='/loader.gif' className='w-[350px]' />
                        </div>
                    </div>
                    :
                    <div className='grid grid-cols-12 relative top-[56px] lg:top-0'>
                        <div className='col-span-12 relative flex'>
                            <CasinoVideo />
                            <div className='absolute bg-[rgba(0,_0,_0,_.4)] h-auto left-[0] content-[""] p-[3px]'>
                                <div className='flex flex-col'>
                                    <div className="font-bold text-[#fff] !text-[10px]">RID: {casinoRoundId}</div>
                                    <div className='flex flex-col'>
                                        <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>DRAGON</span>
                                        <div className='flex'>
                                            <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.DRAGON?.card_1 : '0'}.png`}></img>
                                        </div>
                                    </div>
                                    <div className='flex flex-col pt-[3px]'>
                                        <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>TIGER</span>
                                        <div className='flex'>
                                            <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.TIGER?.card_1 : '0'}.png`}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                winnerName &&
                                <div className="winnerOverlay absolute h-auto bottom-2/4 ml-auto mr-auto p-[5px] w-full flex justify-center">
                                    <div className="mini-result-new active border-[0] border-[solid] mb-0 text-[#000] bg-[linear-gradient(-180deg,_#fd8f3b_0%,_#fd3523_100%)] w-auto text-[14px] p-[6px] uppercase rounded-tl-[16px] rounded-br-[16px] rounded-tr-[2px] rounded-bl-[2px] min-h-[20px] [transition:all_.5s_cubic-bezier(.168,-.885,.3,2)_.5s] scale-0 scale-100">
                                        <span> {winnerName}</span>
                                    </div>
                                    <span className='absolute' >
                                        <canvas width="100vw" height="100vh">
                                        </canvas>
                                    </span>
                                </div>
                            }


                            <div className="timer-overlay  ">
                                <div className='setting-btn text-white text-[1rem]'>
                                    <i className="fa fa-cog fa-lg text-[#ff8100]"></i>
                                </div>
                                <div className="timer-group">
                                    <div className="timer minute">
                                        <div className="hand">
                                            <span className="">
                                            </span>
                                        </div>
                                        <div className="hand">
                                            <span className="">
                                            </span>
                                        </div>
                                    </div>
                                    <div className="face">
                                        <p id="lazy" className="">{seconds}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='col-span-12 h-[calc(101vh-40vh)] lg:h-auto overflow-auto'>
                            <div className='grid-cols-12'>
                                <div id="marketScroll" className="">
                                    <div>
                                        <ol className='rounded-[100vmax] m-0 p-0 [list-style:none] [counter-reset:liCount_7] grid gap-[calc(.5rem*2)]'>
                                            <div className="text-[16px] pl-[42px] pr-[50px] relative top-[9px] font-bold !justify-between !flex">

                                                {
                                                    marketState && marketState?.length > 0 && marketState?.map((market, i) => (
                                                        market?.marketName === "WINNER" && market?.runners && market?.runners?.length && market?.runners?.map((runner, i) => {
                                                            return (
                                                                <span> {market?.runnersName[runner?.selectionId]} </span>
                                                            )
                                                        })
                                                    ))
                                                }
                                            </div>
                                            <li className="m-[.5rem] mb-0 mt-0 bg-[#f2f2f2] text-[#0d0d0d] items-center gap-[calc(.5rem*4)] [content-increment:liCount] relative [box-shadow:calc(.5rem_*_.25)_calc(.5rem_*_.25)_.5rem_rgba(0,_0,_0,_.5)] rounded-[100vmax] !justify-between flex overflow-hidden">

                                                {
                                                    marketState && marketState.length > 0 &&
                                                    marketState.map((market, i) => (
                                                        market?.marketName === "WINNER" && market?.runners && market?.
                                                            runners.length && market.runners.map((runner, i) => {
                                                                const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended
                                                                return (
                                                                    <div>
                                                                        <div className="flex">
                                                                            <div className={`text-[12px] font-bold w-[66px] rounded-tl-[100vmax] overflow-hidden rounded-bl-[100vmax] bg-[#a7d8fd] cursor-pointer px-[1.5rem] p-[1rem] !items-center flex-col flex runner-box runners_${market.marketId}  ${isSuspended ? 'suspended' : ''}`} data-id={runner.selectionId}
                                                                                onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                                    casinoEventId,
                                                                                    casinoRoundId,
                                                                                    market.marketId,
                                                                                    true,
                                                                                    runner?.price?.back[0]?.size,
                                                                                    false,
                                                                                    runner.selectionId,
                                                                                    market?.runnersName[runner?.selectionId],
                                                                                    runner?.price?.back[0]?.price,
                                                                                    false,
                                                                                    market.marketName
                                                                                ) : null}>
                                                                                <span className='text-[12px] font-bold' >{runner.price.back[0].price}</span>
                                                                                <span className='text-[12px] font-bold' >{runner.price.back[0].size}</span>
                                                                            </div>
                                                                            <div className={`text-[12px] font-bold w-[66px] rounded-tr-[100vmax] overflow-hidden rounded-br-[100vmax] bg-[#f9c9d4] cursor-pointer px-[1.5rem] p-[1rem] !items-center flex-col flex runners_${market.marketId}  ${isSuspended ? 'suspended' : ''}`} data-id={runner.selectionId}
                                                                                onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                                    casinoEventId,
                                                                                    casinoRoundId,
                                                                                    market.marketId,
                                                                                    true,
                                                                                    runner?.price?.lay[0]?.size,
                                                                                    false,
                                                                                    runner.selectionId,
                                                                                    market?.runnersName[runner?.selectionId],
                                                                                    runner?.price?.lay[0]?.price,
                                                                                    false,
                                                                                    market.marketName
                                                                                ) : null}>
                                                                                <span className='text-[12px] font-bold' >{runner.price.lay[0].price}</span>
                                                                                <span className='text-[12px] font-bold' >{runner.price.lay[0].size}</span>
                                                                            </div>

                                                                        </div>
                                                                        <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}></div>

                                                                    </div>
                                                                )
                                                            })

                                                    ))


                                                }
                                            </li>
                                            {showBetsSlip && BetPlaceData.market_name == "WINNER" && market_id && (

                                                <CasinoBetPlace type={type} BetPlaceData={BetPlaceData} placeStakeValue={placeStakeValue} StakeValue={StakeValue} chips={chips} cancelBetSlip={cancelBetSlip} placing={placing} betPlace={betPlace} />

                                            )}
                                        </ol>


                                        <div className='mt-[.25rem] w-[100%] ml-[auto] mr-[auto]'>
                                            <div >
                                                {marketState && marketState.length > 0 && marketState.map((market, i) => (
                                                    market?.marketName === "PAIR" && market?.runners && market?.runners.length && market.runners.map((runner, i) => {
                                                        const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                        return (
                                                            <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                                <div className='w-[100%]'>
                                                                    <button className={`h-[60px] overflow-hidden rounded-[22px] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)] text-black p-[6px] text-[14px] w-[100%] relative uppercase px-[3rem] mt-[.25rem] flex items-center justify-between runners_${market.marketId}  ${isSuspended ? 'suspended' : ''}`} data-id={runner.selectionId}
                                                                        onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                            casinoEventId,
                                                                            casinoRoundId,
                                                                            market.marketId,
                                                                            true,
                                                                            runner?.price?.back[0]?.size,
                                                                            false,
                                                                            runner.selectionId,
                                                                            market?.runnersName[runner?.selectionId],
                                                                            runner?.price?.back[0]?.price,
                                                                            false,
                                                                            market.marketName
                                                                        ) : null}>
                                                                        {market?.runnersName[runner?.selectionId]}
                                                                        <b >{runner.price['back'][0].price}</b>
                                                                    </button>
                                                                    <p className='mb-0 p-0'>
                                                                        <span className="profitLoss-font"></span>
                                                                    </p>
                                                                    <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}></div>

                                                                </div>
                                                            </div>
                                                        )
                                                    })))}
                                                <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                    <div className='w-[100%] text-right'>
                                                        <p className='h-[18px] leading-[18px] mb-0 mt-0 text-[10px]'> Min:100 Max:100000 </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {showBetsSlip && BetPlaceData.market_name == "PAIR" && market_id && (

                                                <CasinoBetPlace type={type} BetPlaceData={BetPlaceData} placeStakeValue={placeStakeValue} StakeValue={StakeValue} chips={chips} cancelBetSlip={cancelBetSlip} placing={placing} betPlace={betPlace} />

                                            )}
                                        </div>

                                        <ul className='flex w-[100%] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)]'>
                                            <li className={`w-[50%] py-[7px] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)] uppercase text-center text-black ${cardType == 'DRAGON CARD' && 'border-t-[2px] border-t-[#000]'} cursor-pointer`}>
                                                <a data-toggle="tab" className='cursor-pointer text-[#000] text-[16px] font-bold text-center w-[100%] flex items-center justify-center' onClick={() => setCardType('DRAGON CARD')}>Dragon</a>
                                            </li>
                                            <li className={`w-[50%] py-[7px] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)] uppercase text-center text-black ${cardType == 'TIGER CARD' && 'border-t-[2px] border-t-[#000]'} cursor-pointer`}>
                                                <a data-toggle="tab" className='cursor-pointer text-[#000] text-[16px] font-bold text-center w-[100%] flex items-center justify-center' onClick={() => setCardType('TIGER CARD')}>Tiger</a>
                                            </li>
                                        </ul>

                                        <div className='mt-[.5rem] p-[5px] mx-auto w-[100%]'>
                                            <div className='-ml-[5px] -mr-[5px] flex flex-wrap w-[100%]'>
                                                <div className='w-[50%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                    <div>
                                                        <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap w-[100%]'>
                                                            {marketState && marketState.length > 0 && marketState.map((market, i) =>

                                                            (
                                                                market?.marketName === "DRAGON ODD/EVEN" && market?.runners && market?.runners.length && market.runners.map((runner, i) => {
                                                                    const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                                    return (
                                                                        <div className={`w-[50%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)] runners_${market.marketId} `} data-id={runner.selectionId} onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                            casinoEventId,
                                                                            casinoRoundId,
                                                                            market.marketId,
                                                                            true,
                                                                            runner?.price?.back[0]?.size,
                                                                            false,
                                                                            runner.selectionId,
                                                                            market?.runnersName[runner?.selectionId],
                                                                            runner?.price?.back[0]?.price,
                                                                            false,
                                                                            market.marketName
                                                                        ) : null}>
                                                                            <p className='h-[18px] text-center mb-0 block'>
                                                                                <b >{runner.price['back'][0].price}</b>
                                                                            </p>
                                                                            <button className={`overflow-hidden w-full h-[60px] rounded-[22px] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)] text-black p-[6px] text-[9px] relative uppercase mt-[.25rem]  ${isSuspended ? 'suspended' : ''}`}>
                                                                                {market?.runnersName[runner?.selectionId]
                                                                                }
                                                                            </button>
                                                                            <p className='mb-0'>
                                                                                <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}></div>

                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }))
                                                            )}
                                                        </div>
                                                        <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap w-[100%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                            <div className='w-full text-right'>
                                                                <p className="mb-0 min-max h-18 text-[10px]"> Min:100 Max:100000 </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-[50%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                    <div>
                                                        <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap w-[100%]'>
                                                            {marketState && marketState.length > 0 && marketState.map((market, i) => (
                                                                market?.marketName === "TIGER ODD/EVEN" && market?.runners && market?.runners.length && market.runners.map((runner, i) => {
                                                                    const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                                    return (
                                                                        <div className={`w-[50%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)] runners_${market.marketId} `} data-id={runner.selectionId} onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                            casinoEventId,
                                                                            casinoRoundId,
                                                                            market.marketId,
                                                                            true,
                                                                            runner?.price?.back[0]?.size,
                                                                            false,
                                                                            runner.selectionId,
                                                                            market?.runnersName[runner?.selectionId],
                                                                            runner?.price?.back[0]?.price,
                                                                            false,
                                                                            market.marketName
                                                                        ) : null}>
                                                                            <p className='h-[18px] text-center mb-0 block'>
                                                                                <b >{runner.price['back'][0].price}</b>
                                                                            </p>
                                                                            <button className={`overflow-hidden w-full h-[60px] rounded-[22px] bg-[linear-gradient(-180deg,#14805e_0,_#184438_100%)]  p-[6px] text-[20px] relative uppercase mt-[.25rem]  ${isSuspended ? 'suspended' : ''}`}>
                                                                                {market?.runnersName[runner?.selectionId] == 'TIGER ODD' ?
                                                                                    <span className='text-[red]'> ♦ ♥ </span> :
                                                                                    <span className='text-[black]'>♣ ♠</span>
                                                                                }
                                                                            </button>
                                                                            <p className='mb-0'>
                                                                                <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}></div>

                                                                            </p>
                                                                        </div>
                                                                    )
                                                                })))}
                                                        </div>
                                                        <div className='-ml-[5px] -mr-[5px] bg-[#f1f5f8] py-[5px] flex flex-wrap w-[100%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                            <div className='w-full text-right'>
                                                                <p className="mb-0 min-max h-18 text-[10px]"> Min:100 Max:100000 </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            {
                                                showBetsSlip &&
                                                market_id &&
                                                (BetPlaceData.market_name === "DRAGON ODD/EVEN" || BetPlaceData.market_name === "TIGER ODD/EVEN") && (
                                                    <CasinoBetPlace
                                                        type={type}
                                                        BetPlaceData={BetPlaceData}
                                                        placeStakeValue={placeStakeValue}
                                                        StakeValue={StakeValue}
                                                        chips={chips}
                                                        cancelBetSlip={cancelBetSlip}
                                                        placing={placing}
                                                        betPlace={betPlace}
                                                    />
                                                )
                                            }

                                        </div>

                                        <div className='bg-[#f1f5f8] p-[5px] mt-[.5rem] w-[100%]'>
                                            <div className='-ml-[5px] -mr-[5px] flex flex-wrap w-[100%]'>
                                                <div className='h-[18px]  mr-[1.5rem] w-[41.66%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)] text-[12px] font-bold'>
                                                    <b className='uppercase'>{cardType}</b>
                                                </div>
                                                <div className='h-[18px] text-center justify-between flex w-[50%] pr-[calc(1.5rem*.5)] pl-[calc(1.5rem*.5)]'>
                                                    <b >12</b>
                                                    <div className='float-right'>
                                                        <i data-bs-toggle="dropdown" aria-expanded="false" className="fas fa-info-circle float-right" data-bs-target="#collapse129210110065012" id="#collapse129210110065012"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='-ml-[5px] -mr-[5px] mt-[.5rem] flex flex-wrap w-[100%]'>
                                                <div className='w-[100%] text-center pr-[calc(1.5rem*.2)] pl-[calc(1.5rem*0)]'>
                                                    {marketState && marketState.length > 0 && marketState.map((market, i) => (
                                                        market?.marketName === cardType && market?.runners && market?.runners.length && market.runners.map((runner, i) => {
                                                            const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended

                                                            return (
                                                                <div className={`mr-[10px] inline-block runners_${market.marketId}  `} data-id={runner.selectionId} onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                    casinoEventId,
                                                                    casinoRoundId,
                                                                    market.marketId,
                                                                    true,
                                                                    runner?.price?.back[0]?.size,
                                                                    false,
                                                                    runner.selectionId,
                                                                    market?.runnersName[runner?.selectionId],
                                                                    runner?.price?.back[0]?.price,
                                                                    false,
                                                                    market.marketName
                                                                ) : null}>
                                                                    <div className={`overflow-hidden ${isSuspended ? 'suspended' : ''}`}>
                                                                        <img className='w-[40px]' src={`https://casino.kingexch9.com/assets/t10cards/${market?.runnersName[runner?.selectionId]}.jpeg`}></img>
                                                                    </div>
                                                                    <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}></div>

                                                                </div>
                                                            )
                                                        })))}
                                                </div>
                                            </div>

                                            {
                                                showBetsSlip &&
                                                market_id &&
                                                (BetPlaceData.market_name === "DRAGON CARD" || BetPlaceData.market_name === "TIGER CARD") && (
                                                    <CasinoBetPlace
                                                        type={type}
                                                        BetPlaceData={BetPlaceData}
                                                        placeStakeValue={placeStakeValue}
                                                        StakeValue={StakeValue}
                                                        chips={chips}
                                                        cancelBetSlip={cancelBetSlip}
                                                        placing={placing}
                                                        betPlace={betPlace}
                                                    />
                                                )
                                            }

                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>

            }
        </>
    )
}
// export default Casino;
