import '../../App.css'
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { signal } from "@preact/signals-react";
import MobileTableRow from '../mobileTableRow';
import TableRow from '../TableRow';
import SkyLoader from '../../components/sky-loader'
import { useSelector } from 'react-redux';

export const eventId = signal("");
function Tennis() {
    const { id } = useParams();
    // eventId.value = id;
    const userInfos = useSelector((state) => state.events); // Use selector to access state

    const [events, setEvents] = useState([])
    const [allEvents, setAllEvents] = useState([])


    useEffect(() => {
        if (userInfos.events.length) {
            setAllEvents(userInfos.events[0]?.competitions[0].events)
        }
    }, [userInfos.events.length])

    useEffect(() => {
        if (allEvents.length > 0) {
            const tempEvents = allEvents.filter(event => event.event_type == 2)
            // const inPlay = allEvents.filter(event => event.is_inplay == 'True')
            setEvents(tempEvents)
        }
    }, [allEvents])

    return (
        <>
            {!events.length > 0 && (
               <SkyLoader />
            )}
            {/* Desktop */}
            {events.length > 0 && (
                <table className="w-full hidden lg:inline-table">
                    <thead className="#dddcd6 bg-[#dddcd6] ">
                        <tr>
                            <th scope="col" className="text-[0.688rem] "></th>
                            <th scope="col" className="text-[0.688rem] "></th>
                            <th scope="col" className="text-[0.688rem] ">1</th>
                            <th scope="col" className="text-[0.688rem] ">X</th>
                            <th scope="col" className="text-[0.688rem] ">2</th>
                            <th scope="col" className="text-[0.688rem] "></th>
                        </tr>
                    </thead>
                    <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                        {events.map((event, index) => (
                            <TableRow key={index} row={event} />
                        ))}
                    </tbody>
                </table>
            )}
            {/* Mobile */}
            {events.length > 0 && (
                <table className="w-full  lg:hidden">
                    <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                        {events.map((event, index) => (
                            <MobileTableRow key={index} row={event} />
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
}

export default Tennis;
