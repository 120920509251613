import { useParams } from 'react-router-dom';
import TEENPATTI2020 from '../20-20_TEENPATTI'
import Lucky7 from '../lucky7';
import DRAGONTIGER2020 from '../20-20_DRAGON_TIGER'
import ANDARBAHARA from '../ANDAR_BAHAR_A'
import CARDS32A from '../32_CARDS_A'
import TEENPATTI1DAY from '../1-DAY_TEENPATTI'
import BACCARAT from '../BACCARAT'
import POKERA2020 from '../POKERA2020'
import DRAGONTIGER from '../DRAGONTIGER'
import DRAGONTIGER1DAY from '../DRAGONTIGER1DAY'
import Appconfig from '@/../config/config'

const Casino = () => {
    const { id } = useParams();

    switch (id) {
        case Appconfig.casino_type_id['t20']:
            return <TEENPATTI2020 />;
        case Appconfig.casino_type_id['Lucky7A']:
            return <Lucky7 />;
        case Appconfig.casino_type_id['dt20']:
            return <DRAGONTIGER2020 />;
        case Appconfig.casino_type_id['ab']:
            return <ANDARBAHARA />;
        case Appconfig.casino_type_id['32c']:
            return <CARDS32A />;
        case Appconfig.casino_type_id['1daytp']:
            return <TEENPATTI1DAY />;
        case Appconfig.casino_type_id['baccarat']:
            return <BACCARAT />;
        case Appconfig.casino_type_id['poker20A']:
            return <POKERA2020 />;
        case Appconfig.casino_type_id['dt']:
            return <DRAGONTIGER />;
        case Appconfig.casino_type_id['1daydt']:
            return <DRAGONTIGER1DAY />;
        default:
            return <div>Casino not found</div>;
    }
};

export default Casino;