import '../../App.css'

function CasinoVideo() {

    return (

        <video className='casinovideo' id="remoteVideo" width="100%" height="100%" autoplay="" playsinline="" muted="" style={{  backgroundSize: 'contain' }}>
                          
        </video>
    )

}

export default CasinoVideo;

