import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import LeftSidebar from './components/leftSidebar';
import Header from './components/header';
import RightSidebar from './components/rightSidebar';
import FooterNav from './components/footerNav';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(

  // <React.StrictMode>
  //   <App />

  // </React.StrictMode>
  // <React.StrictMode>

 
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <div className='h-screen'>
          <div className="grid grid-cols-12  ">
            <div className='col-span-12 lg:h-[6.25rem]'>
              <Header />
            </div>
            <div className='col-span-12 flex'>
              <div className='hidden lg:flex xl:flex w-full max-w-[17.4vw] ml-[20px] mr-[8px] ' >
                <LeftSidebar />

              </div>
              <div className='w-full lg:max-w-[55vw]' >
                <App />
              </div>
              <div className='hidden lg:flex xl:flex w-full max-w-[25vw] ml-[9px] ' >
                <RightSidebar />
              </div>
            </div >
            <div className='col-span-12 lg:hidden '>
              <FooterNav />
            </div >
          </div >
        </div >
        </BrowserRouter>

      </PersistGate>
    </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
