import '../../App.css'
import { useState } from 'react';
import { FaUser } from "react-icons/fa";
import { FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Appconfig from '../../config/config'
import axios from 'axios';
import SkyLoader from '../../components/sky-loader'
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../redux/slice/userInfo/userInfoSlice';
import { getNews } from '../../redux/slice/news/newsSlice';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const [user_nameValidationError, setUser_NameValidationError] = useState("Please enter username");
    const [passwordValidationError, setPasswordValidationError] = useState("Please enter password");
    const [validationError, setValidationError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setformData] = useState({
        user_name: '',
        password: '',
        site_code: Appconfig.sitecodes,
        user_type: "User"
    });

    const [isDisabled, setIsDisabled] = useState(false);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
  
    function checkValidation() {
        let errorText;
        if (formData.user_name.trim() === "") {
            setUser_NameValidationError('Please enter username')
        }
        if (formData.password === "") {
            document.querySelector('.password-error').classList.remove('hidden');
            setPasswordValidationError('Please enter password')
        }
        const regex = /^[a-zA-Z0-9]*$/;
        if (!regex.test(formData.user_name)) {
            document.querySelector('.username-error').classList.remove('hidden');
            setUser_NameValidationError('Username is only allow a-z and 0-9.')
            return false;
        }

        setValidationError(errorText);
        if (regex.test(formData.user_name) && formData.password !== "") {
            return true
        }
        // return false;

    }


    const handleInputChange = (event) => {
        setformData({
            ...formData,
            [event.target.name]: event.target.value
        });
        if (event.target.name === 'user_name') {
            document.querySelector('.username-error').classList.add('hidden');
        }
        if (event.target.name === 'password') {
            document.querySelector('.password-error').classList.add('hidden');
        }

    };


    const handleSubmit = (event) => {
        event.preventDefault();
        if (checkValidation()) {

            setIsDisabled(true);
            setIsLoading(true)

            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/userAuthenticate`,
                headers: {
                    'Content-Type': 'application/json',

                },

                data: JSON.stringify(formData)

            };
            axios(config)
                .then(function (response) {
                    setIsDisabled(false);
                    setIsLoading(false)

                    // setformData({})
                    formData.user_name = ""
                    formData.password = ""
                    if (response.data.result != 0) {
                        NotificationManager.success(response.data.resultMessage, '', 3000);

                        dispatch(getNews({
                            site_id: Appconfig.superAdmin,
                        }));


                        dispatch(setUserInfo({ key: "userdata", value: response.data.resultData && response.data.resultData }))

                        // localStorage.setItem('userdata', JSON.stringify(response.data.resultData && response.data.resultData));
                        localStorage.setItem('login_token', response.data.resultData && response.data.resultData.login_token);
                        setTimeout(() => {
                            setIsLoading(false)
                            navigate('/')
                        }, 1000)
                        // storeUserinfo(response.data.resultData);
                    }
                    else {
                        setValidationError(response.data.resultMessage);
                        console.log('--', response.data.resultMessage)
                        // errorNotification();
                        NotificationManager.error('Invalid Login Credential!', '', 3000);
                    }

                })
                .catch(function (error) {
                    setIsDisabled(false);
                    setIsLoading(false)

                    console.log(error);
                });

        }

    }

    const loginDemoID = async () => {

        setIsDisabled(true);
        setIsLoading(true)

        var demoData = {
            'user_name': 'demo_user',
            'password': '123456',
            'site_code': Appconfig.sitecodes,
            'user_type': "User"
        }


        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}users/userAuthenticate`,
            headers: {
                'Content-Type': 'application/json',

            },

            data: JSON.stringify(demoData)

        };
        axios(config)
            .then(function (response) {
                setIsDisabled(false);

                if (response.data.result != 0) {
                    NotificationManager.success(response.data.resultMessage, '', 3000);
                    setIsLoading(true)
                    dispatch(setUserInfo({ key: "userdata", value: response.data.resultData && response.data.resultData }))

                    // localStorage.setItem('userdata', JSON.stringify(response.data.resultData && response.data.resultData));
                    localStorage.setItem('login_token', response.data.resultData && response.data.resultData.login_token);
                    setTimeout(() => {
                        setIsLoading(false)
                        navigate('/')
                    }, 1000)
                    // storeUserinfo(response.data.resultData);
                }
                else {
                    setValidationError(response.data.resultMessage);
                    console.log('--', response.data.resultMessage)
                    // errorNotification();
                    NotificationManager.error('Invalid Login Credential!', '', 3000);
                }

            })
            .catch(function (error) {
                setIsDisabled(false);

                console.log(error);
            });

    }

    return (
        <>
            <NotificationContainer />
            {isLoading && (
                <SkyLoader />
            )}
            {/* Desktop */}
            <div className='hidden lg:block absolute w-full left-0 top-0 '>
                <div className=' relative w-full flex items-center justify-center h-screen overflow-hidden bg-[linear-gradient(-180deg,_#f6a21e_0,_#cb7e05_100%)] z-[999]'>
                    <div className=' max-w-[512px] mt-[0] mx-[auto]  mb-[35%] bg-[#f6a21e] p-[20px] rounded-sm mt-[auto] mb-[auto]'>
                        <Link to={'/'} className='bg-[linear-gradient(-180deg,_#f6a21e_0,_#cb7e05_100%)] absolute text-[21px] top-[21px] right-[40px] px-[5px] py-[5px] rounded-[9px] cursor-pointer' >
                            <FaTimes />
                        </Link>
                        <div className='flex items-center justify-center w-full'>
                            <img alt='' className='align-middle border-none h-[4rem]' src='/logo.png' />
                        </div>
                        <div className='mt-[1.5rem]'>
                            <form method="post" onSubmit={handleSubmit} autoComplete="off" noValidate >
                                <div className='mb-[1.5rem]'>
                                    <div className='relative bg-[white] h-[40px] rounded-[5px] flex items-center'>
                                        <input
                                            value={formData.user_name}
                                            onChange={handleInputChange}
                                            onBlur={(e) => {
                                                if (!e.target.value.trim()) {
                                                    document.querySelector('.username-error').classList.remove('hidden');
                                                }
                                            }}
                                            className='bg-white outline-none lg:h-[33px] text-black border-transparent block w-full px-3 py-2 text-base font-normal leading-normal appearance-none rounded-md transition-border-color duration-150 ease-in-out transition-box-shadow duration-150 ease-in-out'
                                            type="text"
                                            autoFocus
                                            tabIndex="1"
                                            name="user_name"
                                            id="loginName"
                                            placeholder="Username"
                                            maxLength={30}
                                        />

                                        <button type='button' className='text-[black] text-[1rem] pl-3 pr-3 pt-[.375rem] pb-[.375rem]'>
                                            <FaUser />
                                        </button>
                                    </div>
                                    <span className="username-error hidden text-[0.813rem] text-white leading-[14px]">
                                        <div className='leading-[14px] pt-[5px]'>{user_nameValidationError}</div>
                                    </span>
                                </div>

                                <div className='mb-[1.5rem]'>
                                    <div className='relative bg-[white] h-[40px] rounded-[5px] flex items-center'>
                                        <input
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            onBlur={(e) => {
                                                // if (!isValidUserName(e.target.value)) {
                                                if (!e.target.value.trim()) {
                                                    document.querySelector('.password-error').classList.remove('hidden');
                                                }
                                            }}
                                            className='bg-[white] lg:h-[33px] outline-none text-[black] border-transparent block w-full px-[.75rem] py-[.375rem] text-[1rem] font-normal leading-normal appearance-none rounded-[.375rem] [transition:border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out]'
                                            type={showPassword ? 'text' : 'password'}
                                            tabIndex="1"
                                            minLength={6}
                                            id="password"
                                            name="password"
                                            placeholder="Password"

                                        />
                                        <button type='button' className='text-[black] text-[1rem] pl-3 pr-3 pt-[.375rem] pb-[.375rem]' onClick={togglePasswordVisibility}>
                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                        </button>
                                    </div>
                                    <span className="password-error hidden text-[0.813rem] text-white leading-[14px]">
                                        <div className='leading-[14px] pt-[5px]'>{passwordValidationError}</div>
                                    </span>
                                </div>


                                <div>
                                    <button type='submit' className='bg-[linear-gradient(-180deg,_#f72424_0%,_#850d0c_100%)] mt-[.25rem] text-[white] [box-shadow:none] rounded-[5px] text-[16px] font-bold leading-[2.6] text-center block w-full border-[unset]' disabled={isDisabled}>
                                        Login
                                    </button>
                                    <button onClick={() => loginDemoID()} className='bg-[linear-gradient(-180deg,_#f72424_0%,_#850d0c_100%)] mt-[.25rem] text-[white] [box-shadow:none] rounded-[5px] text-[16px] font-bold leading-[2.6] text-center block w-full border-[unset]' disabled={isDisabled}>
                                        Login with Demo ID
                                    </button>
                                </div>

                            </form>
                        </div>


                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className='lg:hidden absolute w-full left-0 top-0 z-[99999]'>
                <div className=' relative w-full flex items-center justify-center h-screen overflow-hidden bg-[linear-gradient(-180deg,_#f6a21e_0,_#cb7e05_100%)] z-[999]'>
                    <div className='w-[85%] max-w-[512px] mt-[0] mx-[auto] mb-[35%] bg-[#f6a21e] p-[20px] rounded-sm mt-[auto] mb-[auto]'>

                        <Link to={'/'} className='bg-[linear-gradient(-180deg,_#f6a21e_0,_#cb7e05_100%)] absolute text-[21px] top-[20px] right-[5%] px-[5px] py-[5px] rounded-[9px] cursor-pointer' aria-label="">
                            <FaTimes />
                        </Link>
                        <div className='flex items-center justify-center w-full'>
                            <img alt='' className='align-middle border-none h-[4rem]' src='/logo.png' />
                        </div>
                        <form method="post" onSubmit={handleSubmit} autoComplete="off" noValidate >
                            <div className='mt-[1.5rem]'>
                                <div className='mb-[1.5rem]'>
                                    <div className='relative bg-[white] h-[40px] rounded-[5px] flex items-center'>
                                        <input
                                            value={formData.user_name}
                                            onChange={handleInputChange}
                                            onBlur={(e) => {
                                                if (!e.target.value.trim()) {
                                                    document.querySelector('.username-error').classList.remove('hidden');
                                                }
                                            }}
                                            className='bg-white outline-none text-black border-transparent block w-full px-3 py-2 text-base font-normal leading-normal appearance-none rounded-md transition-border-color duration-150 ease-in-out transition-box-shadow duration-150 ease-in-out'
                                            type="text"
                                            autoFocus
                                            tabIndex="1"
                                            name="user_name"
                                            id="loginName"
                                            placeholder="Username"
                                            maxLength={30}
                                        />

                                        <button type='button' className='text-[black] text-[1rem] pl-3 pr-3 pt-[.375rem] pb-[.375rem]'>
                                            <FaUser />
                                        </button>
                                        <span className="username-error hidden text-[0.813rem] text-white leading-[14px]">
                                            <div className='leading-[14px] pt-[5px]'>{user_nameValidationError}</div>
                                        </span>
                                    </div>
                                    <span className="username-error hidden text-[0.938rem] text-white leading-[14px]">
                                        <div className='leading-[14px]'> Please enter username.</div>
                                    </span>
                                </div>

                                <div className='mb-[1.5rem]'>
                                    <div className='relative bg-[white] h-[40px] rounded-[5px] flex items-center'>
                                        <input
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            onBlur={(e) => {
                                                // if (!isValidUserName(e.target.value)) {
                                                if (!e.target.value.trim()) {
                                                    document.querySelector('.password-error').classList.remove('hidden');
                                                }
                                            }}
                                            className='bg-[white] outline-none text-[black] border-transparent block w-full px-[.75rem] py-[.375rem] text-[1rem] font-normal leading-normal appearance-none rounded-[.375rem] [transition:border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out]'
                                            type={showPassword ? 'text' : 'password'}
                                            tabIndex="1"
                                            minLength={6}
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                        />
                                        <button type='button' className='text-[black] text-[1rem] pl-3 pr-3 pt-[.375rem] pb-[.375rem]' onClick={togglePasswordVisibility}>
                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                        </button>
                                    </div>
                                    <span className="password-error hidden text-[0.813rem] text-white leading-[14px]">
                                        <div className='leading-[14px] pt-[5px]'>{passwordValidationError}</div>
                                    </span>
                                </div>


                                <div>
                                    <button type='submit' className='bg-[linear-gradient(-180deg,_#f72424_0%,_#850d0c_100%)] text-[white] [box-shadow:none] rounded-[5px] text-[16px] font-bold leading-[2.6] text-center block w-full border-[unset]' disabled={isDisabled}>
                                        Login
                                    </button>
                                    <button onClick={() => loginDemoID()} className='bg-[linear-gradient(-180deg,_#f72424_0%,_#850d0c_100%)] mt-[.25rem] text-[white] [box-shadow:none] rounded-[5px] text-[16px] font-bold leading-[2.6] text-center block w-full border-[unset]' disabled={isDisabled}>
                                        Login with Demo ID
                                    </button>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Login